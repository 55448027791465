import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactComponent } from './contact/contact.component';
import { HomeComponent } from './home/home.component';
import { PracticeComponent } from './practice/practice.component';
import { AmalProjectComponent } from './projects/Amal/amal-project/amal-project.component';
import { ProjectsComponent } from './projects/projects.component';
import { SynergyGiftAndLoyaltyProjectComponent } from './projects/SynergyGiftAndLoyalty/synergy-gift-and-loyalty-project/synergy-gift-and-loyalty-project.component';

const routes: Routes = [
  {path: 'home', redirectTo: '/'},
  {path: 'projects', component: ProjectsComponent},
  {path: 'contact', component: ContactComponent},
  {path: 'practice', component: PracticeComponent},
  {path: 'projects/SynergyGiftAndLoyalty', component: SynergyGiftAndLoyaltyProjectComponent},
  {path: 'projects/Amal', component: AmalProjectComponent},
  {path: '', component: HomeComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
