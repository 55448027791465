import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { $ } from 'protractor';
import * as Tone from 'tone';


const Synth = new Tone.Synth();
const AMSynth = new Tone.AMSynth();
const DuoSynth = new Tone.DuoSynth();
const FMSynth = new Tone.FMSynth();
const MembraneSynth = new Tone.MembraneSynth();
const MetalSynth = new Tone.MetalSynth();
const MonoSynth = new Tone.MonoSynth();
const NoiseSynth = new Tone.NoiseSynth();
const PluckSynth = new Tone.PluckSynth();
const PolySynth = new Tone.PolySynth();

var Instruments = {
  'Synth': Synth,
  'AMSynth': AMSynth,
  'DuoSynth': DuoSynth,
  'FMSynth': FMSynth,
  'MembraneSynth': MembraneSynth,
  'MetalSynth': MetalSynth,
  'MonoSynth': MonoSynth,
  'NoiseSynth': NoiseSynth,
  'PluckSynth': PluckSynth,
  'PolySynth': PolySynth
}

const AutoWah = new Tone.AutoWah();
const BitCrusher = new Tone.BitCrusher();
const Chebyshev = new Tone.Chebyshev();
const Chorus = new Tone.Chorus(4,2,1);
const Drive = new Tone.Distortion(1);
const Delay = new Tone.FeedbackDelay();
const Phaser = new Tone.Phaser();
const PitchShift = new Tone.PitchShift();
const Reverb = new Tone.Reverb();
const Tremolo = new Tone.Tremolo();
const Vibrato = new Tone.Vibrato();

var Effects = {
  'BitCrusher': BitCrusher,
  'Chebyshev': Chebyshev,
  'Chorus': Chorus,
  'Drive': Drive,
  'Delay': Delay,
  'Phaser': Phaser,
  'Pitch': PitchShift,
  'Reverb': Reverb,
  'Tremolo': Tremolo,
  'Vibrato': Vibrato,
  'Wah': AutoWah
}


@Component({
  selector: 'app-practice',
  templateUrl: './practice.component.html',
  styleUrls: ['./practice.component.css']
})
export class PracticeComponent implements OnInit {

  userSynth;
  selectedInstrument: string = 'Synth';
  engagedEffects: string[] = [];
  templateEffects = ['BitCrusher','Chebyshev','Chorus','Drive', 'Delay','Phaser', 'Pitch', 'Reverb', 'Tremolo', 'Vibrato', 'Wah'];

  constructor() { 
    this.userSynth = Instruments[this.selectedInstrument];
    this.userSynth.toDestination();
    console.log("Effects are janky!");

  }

  @HostListener('window:keydown', ['$event']) kybPlayPad(event:any){
    //console.log(event.key);

    if (event.key == "`") {this.userSynth.triggerAttackRelease("B3", "8n", );}
    if (event.key == 1) {this.userSynth.triggerAttackRelease("C4", "8n", );}
    if (event.key == 2) {this.userSynth.triggerAttackRelease("C#4", "8n", );}
    if (event.key == 3) {this.userSynth.triggerAttackRelease("D4", "8n", );}
    if (event.key == 4) {this.userSynth.triggerAttackRelease("D#4", "8n", );}
    if (event.key == 5) {this.userSynth.triggerAttackRelease("E4", "8n", );}
    if (event.key == 6) {this.userSynth.triggerAttackRelease("F4", "8n", );}
    if (event.key == 7) {this.userSynth.triggerAttackRelease("F#4", "8n", );}
    if (event.key == 8) {this.userSynth.triggerAttackRelease("G4", "8n", );}
    if (event.key == 9) {this.userSynth.triggerAttackRelease("G#4", "8n", );}
    if (event.key == 0) {this.userSynth.triggerAttackRelease("A4", "8n", );}
    if (event.key == "-") {this.userSynth.triggerAttackRelease("A#4", "8n", );}
    if (event.key == "=") {this.userSynth.triggerAttackRelease("B4", "8n", );}


    if (event.key == "q") {this.userSynth.triggerAttackRelease("G3", "8n", );}
    if (event.key == "w") {this.userSynth.triggerAttackRelease("G#3", "8n", );}
    if (event.key == "e") {this.userSynth.triggerAttackRelease("A3", "8n", );}
    if (event.key == "r") {this.userSynth.triggerAttackRelease("A#3", "8n", );}
    if (event.key == "t") {this.userSynth.triggerAttackRelease("B3", "8n", );}
    if (event.key == "y") {this.userSynth.triggerAttackRelease("C4", "8n", );}
    if (event.key == "u") {this.userSynth.triggerAttackRelease("C#4", "8n", );}
    if (event.key == "i") {this.userSynth.triggerAttackRelease("D4", "8n", );}
    if (event.key == "o") {this.userSynth.triggerAttackRelease("D#4", "8n", );}
    if (event.key == "p") {this.userSynth.triggerAttackRelease("E4", "8n", );}
    if (event.key == "[") {this.userSynth.triggerAttackRelease("F4", "8n", );}
    if (event.key == "]") {this.userSynth.triggerAttackRelease("F#4", "8n", );}
    if (event.key == "\\") {this.userSynth.triggerAttackRelease("G4", "8n", );}

    if (event.key == "a") {this.userSynth.triggerAttackRelease("D3", "8n", );}
    if (event.key == "s") {this.userSynth.triggerAttackRelease("D#3", "8n", );}
    if (event.key == "d") {this.userSynth.triggerAttackRelease("E3", "8n", );}
    if (event.key == "f") {this.userSynth.triggerAttackRelease("F3", "8n", );}
    if (event.key == "g") {this.userSynth.triggerAttackRelease("F#3", "8n", );}
    if (event.key == "h") {this.userSynth.triggerAttackRelease("G3", "8n", );}
    if (event.key == "j") {this.userSynth.triggerAttackRelease("G#3", "8n", );}
    if (event.key == "k") {this.userSynth.triggerAttackRelease("A3", "8n", );}
    if (event.key == "l") {this.userSynth.triggerAttackRelease("B3", "8n", );}
    if (event.key == ";") {this.userSynth.triggerAttackRelease("B#3", "8n", );}

    if (event.key == "z") {this.userSynth.triggerAttackRelease("A2", "8n", );}
    if (event.key == "x") {this.userSynth.triggerAttackRelease("A#2", "8n", );}
    if (event.key == "c") {this.userSynth.triggerAttackRelease("B2", "8n", );}
    if (event.key == "v") {this.userSynth.triggerAttackRelease("C3", "8n", );}
    if (event.key == "b") {this.userSynth.triggerAttackRelease("C#3", "8n", );}
    if (event.key == "n") {this.userSynth.triggerAttackRelease("D3", "8n", );}
    if (event.key == "m") {this.userSynth.triggerAttackRelease("D#3", "8n", );}
    if (event.key == ",") {this.userSynth.triggerAttackRelease("E3", "8n", );}
    if (event.key == ".") {this.userSynth.triggerAttackRelease("F3", "8n", );}
  }
  
  ngOnInit(): void {
    //default select Synth instrument
    document.getElementById(this.selectedInstrument).classList.toggle('btn-danger');
  }

  async ChangeInstrument(event){
    document.getElementById(this.selectedInstrument).classList.toggle('btn-danger');
    
    this.selectedInstrument = event.srcElement.id;
    document.getElementById(this.selectedInstrument).classList.toggle('btn-danger');
    
    this.userSynth.disconnect();
    this.userSynth = Instruments[this.selectedInstrument];
    this.userSynth.toDestination();

    this.ChainEffects();

  }

  ConnectEffect(event){
    document.getElementById(event.srcElement.id).classList.toggle('btn-danger');
    if(!this.engagedEffects.includes(event.srcElement.id)){
      this.engagedEffects.push(event.srcElement.id);
    }
    else{
      //remove effect
      this.engagedEffects.forEach(element => {
        if(element === event.srcElement.id){
          this.engagedEffects.splice(this.engagedEffects.indexOf(element),1);
        }
      });
    }
    this.ChainEffects();


  }

  ChainEffects(){
    let effects = [];
    this.engagedEffects.forEach((effect)=>{
      effects.push(Effects[effect]);
    })
    
    if(effects.length === 0){
      this.userSynth.disconnect();
      this.userSynth.toDestination();
    }
    else{
      this.userSynth.disconnect();
      
      this.userSynth.toDestination();
      this.userSynth.chain(...effects, Tone.Destination);
    }
  }
  

}
