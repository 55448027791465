
<div class="container pt-5 title">
    <h1>Amal</h1>
    <hr>
</div>
<!-- Project Description -->
<section class="container pb-5">
    <p>
        Amal is an multi-platform Mobile application built using <b>.Net 5</b> that presents available hotel units as long-term residential units. I provided the code for the initial mockup of the application. 
        Interacting with the client to determine requirements and analyze necessary technologies provided insight into how to build applications from the ground up.
    </p>
    <br>
    <p>
        Using <b>Xamarin.Forms</b>, I primarily worked with <b>XAML</b>, and <b>C#</b> throughout the project. More advanced concepts such as integrating <b>Google Maps API</b>, complex binding in a <b>MVVM</b> architecture, Routing, provided a smooth application experience.
        Common programming practices including using Interfaces, Services, allowed for reusable code throughout the project. Embedded resources such as custom fonts allowed for a more unique theme and user experience. 
        While Xamarin provides platform independent code, a brief time was spent developing in an exclusively <b>Android</b> environment. 

    </p>
</section>
<!-- Screenshots -->
<section class="container pt-5">

    <div class="row d-flex justify-content-center">

        <div class="imgContainer col-md-4">
            <img src="../../../../assets/Images/projects/AmalScreenshots/AmalHomePage1.png"/>
        </div>
        <div class="col-md-8 d-flex align-items-center">
            <p class="">
                The home page with the Amal Logo and some personalized verbiage. A simple <b>Xaml</b> page accomplished this very easily.
            </p>
        </div>

        <div class="w-100 p-5"></div>

        <div class="col-md-8 d-flex align-items-center">
            <p>
                A continuation of the concise home page laying out the steps required to complete the hold process. 
            </p>
        </div>
        <div class="imgContainer col-md-4">
            <img src="../../../../assets/Images/projects/AmalScreenshots/AmalHomePage2.png"/>
        </div>

        <div class="w-100 p-5"></div>

        <div class="imgContainer col-md-4">
            <img src="../../../../assets/Images/projects/AmalScreenshots/AmalFlyoutMenu.png"/>
        </div>
        <div class="col-md-8 d-flex align-items-center">
            <p>
                A flyout menu with a users name and navigation options. 
            </p>
        </div>

        <div class="w-100 p-5"></div>

        <div class="col-md-8 d-flex align-items-center">
            <p>
                The map page integrating the <b>Google Maps API</b>. Including requiring user permission, the app is able to provide location specific results based on a user entered zipcode. Xaml bindings allowed for clear markup and easy readibilty when communicating between front and back end. 
                Custom Renders required exposure to the <b>Android</b> programming environment. 
            </p>
        </div>
        <div class="imgContainer col-md-4">
            <img src="../../../../assets/Images/projects/AmalScreenshots/MapPage.png"/>
        </div>

        <div class="w-100 p-5"></div>

        <div class="imgContainer col-md-4">
            <img src="../../../../assets/Images/projects/AmalScreenshots/MapFilters.png"/>
        </div>
        <div class="col-md-8 d-flex align-items-center">
            <p>
                A variety of binded map filters to sift through results. UI Elements such as pickers, date pickers, and double ended range sliders allowed for more refined filtering.
            </p>
        </div>

        <div class="w-100 p-5"></div>

        <div class="col-md-8 d-flex align-items-center">
            <p>
                A Collection View binded to a backend list offers a scrollable list of results to hotels in the users entered area. 
            </p>
        </div>
        <div class="imgContainer col-md-4">
            <img src="../../../../assets/Images/projects/AmalScreenshots/HotelsList.png"/>
        </div>

        <div class="w-100 p-5"></div>

        <div class="imgContainer col-md-4">
            <img src="../../../../assets/Images/projects/AmalScreenshots/HotelDetails.png"/>
        </div>
        <div class="col-md-8 d-flex align-items-center">
            <p>
                A detail page for the hotel allows the user to virtually tour common areas for the location. A wrappable list of hotel-specific amenities are added for convenience.
            </p>
        </div>

        <div class="w-100 p-5"></div>

        <div class="col-md-8 d-flex align-items-center">
            <p>
                A full list of available units to be rented for long term use. Distinct results for different types of rooms are shown. 
            </p>
        </div>
        <div class="imgContainer col-md-4">
            <img src="../../../../assets/Images/projects/AmalScreenshots/HotelAvailableRooms.png"/>
        </div>

        <div class="w-100 p-5"></div>

        <div class="imgContainer col-md-4">
            <img src="../../../../assets/Images/projects/AmalScreenshots/RoomDetail.png"/>
        </div>
        <div class="col-md-8 d-flex align-items-center">
            <p>
                Room details with a virtual tour of the unit, as well as room-specific amenities.
            </p>
        </div>

        <div class="w-100 p-5"></div>

        <div class="col-md-8 d-flex align-items-center">
            <p>
                Actionable buttons for provided room allows the user to access various forms
            </p>
        </div>
        <div class="imgContainer col-md-4">
            <img src="../../../../assets/Images/projects/AmalScreenshots/RoomDetailActions.png"/>
        </div>

        <div class="w-100 p-5"></div>

        <div class="imgContainer col-md-4">
            <img src="../../../../assets/Images/projects/AmalScreenshots/AskAQuestion.png"/>
        </div>
        <div class="col-md-8 d-flex align-items-center">
            <p>
                User is able to question the hotel for any concerns. A Xamarin form requires a set of information to be entered. Binding is used extensively at this phase.
            </p>
        </div>

        <div class="w-100 p-5"></div>

        <div class="col-md-8 d-flex align-items-center">
            <p>
                User is able to requst a tour of the hotel before making any commitments. Custom Renders to account for different platform API versions was included as well as back-end binding.
            </p>
        </div>
        <div class="imgContainer col-md-4">
            <img src="../../../../assets/Images/projects/AmalScreenshots/TourRequest.png"/>
        </div>

        <div class="w-100 p-5"></div>

        <div class="imgContainer col-md-4">
            <img src="../../../../assets/Images/projects/AmalScreenshots/HoldRequest.png"/>
        </div>
        <div class="col-md-8 d-flex align-items-center">
            <p>
                User is able to hold a unit for variable lengths of time. Provided that the user is logged in, different payment options are available through a picker. 
            </p>
        </div>

        <div class="w-100 p-5"></div>

        <div class="col-md-8 d-flex align-items-center">
            <p>
                The activity page consolidates the users requests for tours and hold requests.
            </p>
        </div>
        <div class="imgContainer col-md-4">
            <img src="../../../../assets/Images/projects/AmalScreenshots/ReservationsPage.png"/>
        </div>
        
        <div class="w-100 p-5"></div>

        <div class="imgContainer col-md-4">
            <img src="../../../../assets/Images/projects/AmalScreenshots/LoginPage.png"/>
        </div>
        <div class="col-md-8 d-flex align-items-center">
            <p>
                A minimal login page for the user to log into their account, or alternitively to retrieve access/ create an account.
            </p>
        </div>
    </div>
</section>