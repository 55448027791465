<div class="container">

    <h1>About:</h1>
    <p>
        I am a Fullstack Software Developer looking for new opportunities to apply my knowledge.
        I can adapt to changes and am able to learn new material fairly quickly. I can work alone and in a team and no matter the situation I will always try my best! I hope to eventually get into DSP or RF Engineering paticularly in regards to audio.
        <i class="bi-music-note"></i>
    </p>
    <hr>
    <h1>Email:</h1>
    <p> shaun2461@gmail.com </p>
</div>