<div class="container-fluid">

  <nav class="navbar navbar-expand-lg navbar-dark bg-dark navbar-custom" role="navigation">
    
      <a class="navbar-brand" routerLink="">Shaun's Resume</a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" routerLink="home">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="projects">Projects</a>
          </li>
          <li class="nav-item" *ngIf="showPractice">
            <a class="nav-link" routerLink="practice">Practice</a>
          </li>
          
        </ul>
      </div>
    
  </nav>
</div>

<router-outlet></router-outlet>