<!-- Jumbotron -->
<section class="container-fluid sect1 parallax jumbotron">
    <div class="container jumbotron">
        <div class="display-4">Welcome! </div>
        <hr class="my-4">
        <p class="lead">I like to learn. <i class="bi-emoji-smile"></i> </p>
    </div>
</section>
<!-- Skills -->
<section class="container-fluid sect2">
    <div class="bannerText">SKILLS</div>
    <div class="container pt-4">

        <h3>Skills & Knowledge</h3>
        <hr>
        <div class="container">
            <ul>
                <li>Familiarity with <b>Fullstack Web Development</b> and <b>Multi-Platform Mobile Development</b> approaches to software solutions</li>
                <li>Extensive exposure to Front-End technologies <b>HTML</b>, <b>CSS</b>, <b>Bootstrap</b>, <b>JavaScript</b>, <b>JQuery</b>, and Frameworks such as <b>Angular</b> to quickly develop web application front-ends</li>
                <li>Back-End technologies <b>Node.js</b>, <b>Firebase Cloud Platform</b>  to facilitate easily scalable and reliable back-end  features</li>
                <li>Use of <b>C#</b>  in <b>.NET Framework</b> and <b>.NET Core</b> environments for large-scale desktop and mobile applications</li>
                <li>Mobile Frameworks such as <b>XAMARIN</b> and <b>.NET 5</b> to develop applications in multiple platforms including <b>Android</b> and <b>iOS</b></li>
                <li>Utilizing <b>Microservices</b> and <b>REST-ful WebAPI’s</b> to receive information to be used within web and mobile applications</li>
                <li>Handling different types of Data-Formats such as <b>JSON</b>, <b>XML</b>, and <b>CSV</b> files</li>
                <li>Exposure to software design principles such as <b>MVC</b>, <b>MVVM</b>, and <b>Component-based</b> models used in multi-project solutions with a focus on <b>CLEAN</b> architecture to ensure efficient scalability and maintainability throughout the project</li>
                <li>Use of <b>Stripe Payment Gateway API</b> to safely handle sensitive user information when developing e-commerce solutions</li>
                <li>Consistent use and growing knowledge of <b>GIT</b> protocols to manage codebase alone and in a team setting </li>
                <li>Foundational knowledge of computer architectures, virtual machines,  operating systems, and network concepts such as <b>TCP/IP</b> and the <b>OSI model</b></li>
                <li>Experience with scripting in <b>Python</b>, and light exposure to <b>Windows Powershell</b> to automate monotonous repetitive tasks</li>
                <li>Audio Programming with <b>JUCE</b> Framework working primarily with <b>C++</b> to develop <b>VST</b> audio plug-ins</li>
                <li>Strong Familiarity with <b>Windows OS</b> and light knowledge of <b>UNIX</b>  systems</li>
                <li>Knowledge and experience with <b>Relational Databases</b> such as <b>SQLServer</b> and light knowledge of non-RDBMS such as <b>NoSQL</b> and <b>MongoDb</b></li>
                <li>Strong knowledge of <b>OOP</b> including languages  <b>Java</b>, <b>C/C++</b>, <b>C#</b>, and concepts  such as encapsulation, inheritance, polymorphism, and abstractions</li>
                <li>Thorough knowledge of <b>Data Structures</b>, and <b>Algorithms</b> to provide a variety of tools to accomplish abstract tasks</li>
                <li>Prevalent use of <b>Visual Studio 2019 IDE</b> and <b>VS Code</b>, <b>Atom</b> Text Editors</li>
                <li>Experience <b>Unit Testing</b> with <b>Jasmine</b> and <b>Karma</b> utilities</li>
                <li>Exposure to software development concepts such as separation of concerns, and a focus on developing concise, reusable, and efficient code</li>
                <li>Highly organized and detail oriented troubleshooting skills from physical to digital domains</li>
                <li>Well-practiced in soldering, and use of multimeters, to analyze, design, and modify basic circuits</li>
                <li>Ability to analyze and design simple circuits</li>
                <li>Operating multimeter's and oscilliscope's</li>
            </ul>
        </div>
        
        <h3 class="mt-5 pt-5">Languages/Technologies:</h3>
        <hr>
        <div class="container skillList">
            <div class="row justify-content-center">
                <div *ngFor="let skill of skills" class="col-3 col-md-1 m-1 col-xs-2">
                    <img src="../../assets/Images/skillEmblems/{{skill.icon}}" alt="{{skill.name}}">
                </div>
            </div>
        </div>
        
        <h3 class="mt-5 pt-5">Soft/Miscellaneous Skills</h3>
        <hr>
        <div class="row">

            <div class="col-md-6">
                <ul>
                    <li>Ability to work individually and in a team setting</li>
                    <li>Open communicator</li>
                    <li>Excellent organizational practices</li>
                    <li>Critical thinking skills and problem solving skills</li>
                </ul>
            </div>
            <div class="col-md-6">
                <ul>
                    <li>Inquisitive mindset and learner-for-life attitude</li>
                    <li>High aptitude and interest in learning new material</li>
                    <li>A resilient positive attitude <i class="bi-emoji-smile-upside-down"></i></li>
                </ul>
            </div>
        </div>

    </div>

</section>
<!-- Education -->
<section class="container-fluid sect3">
    <div class="bannerText">EDUCATION</div>
    <div class="container pt-4">

        <h3>University of North Carolina at Greensboro</h3>
        <hr>
        B.S. Computer Science
        <span style="float: right;">(2019)</span>
        

    </div>

</section>
<!-- Contact -->
<section class="container-fluid sect4">
    <div class="bannerText">CONTACT</div>
    <div class="pt-4"></div>
    <app-contact></app-contact>
</section>

