import { Component, OnInit } from '@angular/core';

class skillBadge{
  name: string;
  icon: string;
 constructor(name,icon){
   this.name = name;
   this.icon = icon;
 }
}
var skillList: skillBadge[] = [
  new skillBadge('Angular','angular.png'),
  new skillBadge('Atom','atom.png'),
  new skillBadge('Bootstrap','bootstrap.png'),
  new skillBadge('C++','cpp.png'),
  new skillBadge('C#','cs.png'),
  new skillBadge('CSS','css.png'),
  new skillBadge('.NET','dotnet.png'),
  new skillBadge('Firebase','firebase.png'),
  new skillBadge('GitHub','github.png'),
  new skillBadge('HTML','html.png'),
  new skillBadge('Javascript','javascript.png'),
  new skillBadge('JUCE','juce.png'),
  new skillBadge('MATLAB','Matlab.png'),
  new skillBadge('Node','node.png'),
  new skillBadge('Python','sql.png'),
  new skillBadge('SQL','vs.png'),
  new skillBadge('VisualStudio','bootstrap.png'),
  new skillBadge('VS Code','vscode.png'),
  new skillBadge('WAMP','wamp.png'),
  new skillBadge('Xamarin','xamarin.png')
];

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor() { }

  skills = skillList;

  ngOnInit(): void {
    

  }

}
