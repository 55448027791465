<section class="container-fluid pt-5 slideIn" >
    <div class="banner jumbotron">
        <div class="display-4">Practice Time </div>
        <p class="lead pt-3">Press keys ...</p>
        
    </div>

    <!--Instrument Selection-->
    <div class="container">
        <h4>Select Instrument: </h4>
        <div class="container d-flex justify-content-center flex-wrap">
            <button class="btn btn-secondary instrumentButton" (click)="ChangeInstrument($event)" id="Synth">Synth</button>
            <button class="btn btn-secondary instrumentButton" (click)="ChangeInstrument($event)" id="AMSynth">AM</button>
            <button class="btn btn-secondary instrumentButton" (click)="ChangeInstrument($event)" id="DuoSynth">Duo</button>
            <button class="btn btn-secondary instrumentButton" (click)="ChangeInstrument($event)" id="FMSynth">FM</button>
            <button class="btn btn-secondary instrumentButton" (click)="ChangeInstrument($event)" id="MembraneSynth">Membrane</button>
            <button class="btn btn-secondary instrumentButton" (click)="ChangeInstrument($event)" id="MetalSynth">Metal</button>
            <button class="btn btn-secondary instrumentButton" (click)="ChangeInstrument($event)" id="MonoSynth">Mono</button>
            <button class="btn btn-secondary instrumentButton" (click)="ChangeInstrument($event)" id="NoiseSynth">Noise</button>
            <button class="btn btn-secondary instrumentButton" (click)="ChangeInstrument($event)" id="PluckSynth">Pluck</button>
            <button class="btn btn-secondary instrumentButton" (click)="ChangeInstrument($event)" id="PolySynth">Poly</button>
        </div>
        
    </div>
    
    <!--Effects Selection-->
    <div class="container"> 
        <h4>Effects:</h4>
        
        <div class="container d-flex justify-content-center" >
            <div *ngFor = "let eff of templateEffects">
                <button class="btn btn-secondary instrumentButton" (click)="ConnectEffect($event)" id={{eff}}>{{eff}}</button> 
            </div>
        </div>
        <div *ngIf = 'engagedEffects.length>0'>Signal Chain: <br><br></div>
        <div *ngFor="let ef of engagedEffects">
            {{ef}}
        </div>
    </div>



</section>
