
<div class="container pt-5 title">
    <h1>Synergy Gift & Loyalty</h1>
    <hr>
</div>
<!-- Project Description -->
<section class="container pb-5">
    <p>
        Synergy Gift & Loyalty is a template project with commonplace features such as a dashboard, profile page, location finder, product page that can be applied to a variety of businesses. 
        Built using <b>.NET Framework</b>, I designed the layout and leveraged available back-end functionality to create the web portion of the application. 
        Adhering to <b>CLEAN</b> Architecture principles ensured the least amount of dependecies and reinforces reliable code.
    </p>
    <br>
    <p>
        I utilized <b>HTML</b>, <b>CSS</b>, and <b>JavaScript</b> as base to accomplish most tasks. Javscript libraries such as <b>JQuery</b> naturally followed promptly. 
        Utilizing technologies such as <b>Angular</b> and <b>Bootstrap</b> expedited the design process. 
        More advanced concepts such as asyncronous programming, Database querying, parsing <b>JSON</b> files, Observables, and calling <b>WebApi</b>/<b>Microservices</b> were also used to provide smoother and more efficient app flow. 
    </p>
</section>
<!-- Screenshots -->
<section class="container pt-5">

    <div class="row d-flex justify-content-center">

        <div class="imgContainer col-md-8">
            <img src="../../../../assets/Images/projects/SynergyScreenshots/MainPage.png"/>
        </div>
        <div class="col-md-4">
            <p>
                The Main Page with the business name title at the top and a sidebar menu to provide site navigation. Provides a series of quick action buttons with a map widget that can potentially be linked to things like global sales.
            </p>
        </div>

        <div class="w-100 p-5"></div>

        <div class="col-md-4">
            <p>
                A series of widgets that can be linked to metrics such as Website views, daily sales, and completed tasks provide insights into critical data. Bootstrap was very useful in laying widgets in a neat grid format.
            </p>
            <br>
            <p>
                Smaller more concise widgets follow for shorter snippets of data to be shown. 
            </p>
        </div>
        <div class="imgContainer col-md-8">
            <img src="../../../../assets/Images/projects/SynergyScreenshots/MainPage2.png"/>
        </div>

        <div class="w-100 p-5"></div>

        <div class="imgContainer col-md-8">
            <img src="../../../../assets/Images/projects/SynergyScreenshots/ProfilePage.png"/>
        </div>
        <div class="col-md-4">
            <p>
                A simple profile page to adjust user information. 
            </p>
        </div>

        <div class="w-100 p-5"></div>

        <div class="col-md-4">
            <p>
                Utilizing asyncronous programming principles and observables, the user is able to add new gift card types and view them in a list with a concise readout of the card info. 
            </p>
        </div>
        <div class="imgContainer col-md-8">
            <img src="../../../../assets/Images/projects/SynergyScreenshots/GiftCardPage.png"/>
        </div>

        <div class="w-100 p-5"></div>

        <div class="imgContainer col-md-8">
            <img src="../../../../assets/Images/projects/SynergyScreenshots/AddGiftCard.png"/>
        </div>
        <div class="col-md-4">
            <p>
                Users are able to add gift cards with binded names. Virtual cards can be generated, or physical cards can be redeemed with an auto-reload amount picker option.
            </p>
        </div>

        <div class="w-100 p-5"></div>

        <div class="col-md-4">
            <p>
                Similar to the Gift Card page, various payment methods can be attached to an account. Bank account info and credit/debit cards can be added with options to select it to see brief details, or to delete the card. 
            </p>
        </div>
        <div class="imgContainer col-md-8">
            <img src="../../../../assets/Images/projects/SynergyScreenshots/BankInfoPage.png"/>
        </div>

        <div class="w-100 p-5"></div>

        <div class="imgContainer col-md-8">
            <img src="../../../../assets/Images/projects/SynergyScreenshots/AddBankAccount.png"/>
        </div>
        <div class="col-md-4">
            <p>
                Adding Bank Accounts are very simple and can be named with Account Numbers, Routing Numbers, an option to make the added card a default payment method as well as another picker for business specific options. 
            </p>
        </div>

        <div class="w-100 p-5"></div>

        <div class="col-md-4">
            <p>
                Adding new credit/debit payment options is possible and straight-forward. Simple HTML form mixed with Angular markup allows for efficient binding and data transfer.
            </p>
        </div>
        <div class="imgContainer col-md-8">
            <img src="../../../../assets/Images/projects/SynergyScreenshots/AddNewBankPayment.png"/>
        </div>

        <div class="w-100 p-5"></div>

        <div class="imgContainer col-md-8">
            <img src="../../../../assets/Images/projects/SynergyScreenshots/MapPage.png"/>
        </div>
        <div class="col-md-4">
            <p>
                A map page provides location specific results for local stores based on user entered zipcode. Form validation, asyncronous programming, and observables are used to provide the results.
            </p>
        </div>

        <div class="w-100 p-5"></div>

        <div class="col-md-4">
            <p>
                The product page which pulls items from an SQL Server Database. SQL Statements, Observables, and Angular markup lays out business products in a neat gridlike template.
            </p>
        </div>
        <div class="imgContainer col-md-8">
            <img src="../../../../assets/Images/projects/SynergyScreenshots/ProductPage.png"/>
        </div>

        <div class="w-100 p-5"></div>

        <div class="imgContainer col-md-8">
            <img src="../../../../assets/Images/projects/SynergyScreenshots/HowItWorks.png"/>
        </div>
        <div class="col-md-4">
            <p>
                A How it works section to provide business information in a single window using a Bootstrap template. A variety of information can be presented here such as descriptions, legal information, and miscellaneous info.
            </p>
        </div>

        <div class="w-100 p-5"></div>

        <div class="col-md-4">
            <p>
                An About Page with the business mission statement pulled from an SQLServer Database. A few widgets for quick metric overview and some social media links are added as well. 
            </p>
        </div>
        <div class="imgContainer col-md-8">
            <img src="../../../../assets/Images/projects/SynergyScreenshots/AboutPage.png"/>
        </div>

        <div class="w-100 p-5"></div>

        <div class="imgContainer col-md-8">
            <img src="../../../../assets/Images/projects/SynergyScreenshots/ContactPage.png"/>
        </div>
        <div class="col-md-4">
            <p>
                A minimal contact page to contact the business using an HTML/Angular form. 
            </p>
        </div>

        <div class="w-100 p-5"></div>


        
    </div>
</section>