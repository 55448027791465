import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-synergy-gift-and-loyalty-project',
  templateUrl: './synergy-gift-and-loyalty-project.component.html',
  styleUrls: ['./synergy-gift-and-loyalty-project.component.css']
})
export class SynergyGiftAndLoyaltyProjectComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
