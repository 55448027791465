<section class="container-fluid">
    <div class="row">

        <!--Resume-->
        <div class="col-md-4 project">
            
            <div class="flip-card">
                <div class="flip-card-inner">
                  <div class="flip-card-front">
                    <img src="./../../assets/Images/bwbg.jpg" alt="Portfolio"  >
                  </div>
                  <div class="flip-card-back" style="background-color: rgb(65, 149, 77);">
                    <h1><i class="bi-lightning"></i><a href="#">Shaun's Resume</a><i class="bi-lightning"></i></h1>
                    <p>A simple portfolio with some neat projects.</p>
                    <!-- <button class="btn btn-dark" data-toggle="collapse" data-target="#projectCollapse">?</button>
                    <div class="col-md-4 project collapse" id="projectCollapse">
                      <br>cloud hosting, angular framework, etc  <br><br> 
                    </div> -->
                  </div>
                </div>
              </div> 
        </div>
        <!-- Always Transient -->
        <div class="col-md-4 project">
            
          <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <img src="./../../assets/Images/projects/covers/mandala.jpg" alt="Always Transient"  >
                </div>
                <div class="flip-card-back" style="background-color: rgb(116, 31, 212);">
                  <h1><a href="https://alwaystransient.com" target="_blank">Always Transient</a></h1>
                  <p>An ecommerce solution built using HTML, CSS, JavaScript, Angular, and Google Cloud Functions to bring local artists into the world of web sales.</p>
                  
                </div>
              </div>
            </div> 
        </div>
        <!-- Synergy Gift & Loyalty -->
        <div class="col-md-4 project">
            
          <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <img src="./../../assets/Images/projects/covers/SynergyGiftAndLoyalty.jpeg" alt="Synergy"  >
                </div>
                <div class="flip-card-back" style="background-color: rgb(182, 159, 28);">
                  <h1><a routerLink="SynergyGiftAndLoyalty">Synergy Gift & Loyalty</a></h1>
                  <p>A web-based template project with dashboard, accounts, and gift and loyalty system features to satisfy a variety of business solutions. Built using C# in the .NET Framework.</p>
                  
                </div>
              </div>
            </div> 
        </div>
        <!-- Amal -->
        <div class="col-md-4 project">
            
          <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <img src="./../../assets/Images/projects/covers/Amal.png" alt="Amal"  >
                </div>
                <div class="flip-card-back" style="background-color: rgb(66, 106, 90);">
                  <h1><a routerLink="Amal">Amal</a></h1>
                  <p>A Multi-Platform Mobile application built using .NET 5 and Xamarin.Forms to present available hotel units as long term residential units.</p>
                  
                </div>
              </div>
            </div> 
        </div>
        <!-- C++ Game -->
        <div class="col-md-4 project">
            
          <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <img src="./../../assets/Images/projects/covers/consoleGame.png" alt="Console Game"  >
                </div>
                <div class="flip-card-back" style="background-color: rgb(141, 37, 37)">
                  <h1><a href="https://github.com/SocksTheDestroyer/Console-Game" target="_blank">Silly C++ Game</a></h1>
                  <p>A classic console style game made very VERY early in my programming journey.</p>
                  
                </div>
              </div>
            </div> 
      </div>
        <!-- Placeholder -->
        <div class="col-md-4 project">
            
            <div class="flip-card">
                <div class="flip-card-inner">
                  <div class="flip-card-front">
                    <img src="./../../assets/Images/projects/covers/untitledProject.jpg" alt="Placeholder"  >
                  </div>
                  <div class="flip-card-back" style="background-color: rgb(224, 33, 33);">
                    <h1>Project ?</h1>
                    <p>Coming soon...Totally happening...any day now...</p>
                    
                  </div>
                </div>
              </div> 
        </div>

    </div>
</section>